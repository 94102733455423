import { image, imageVideo } from "./media";

export const articleInner = groq`
  _key,
  title,
  slug,
  thumbnail{
    ${imageVideo}
  },
  category,
  tags,
  publishedAt,
  lede,
`;

export const masthead = groq`
  "masthead": *[_type == "blog"][0]{
    title,
    slug,
    titleImage{
      ${image}
    },
    "categories": *[_type == 'blogCategory'] | order(label asc) {
      label,
      value
    },
    "tags": *[_type == "blogTag"] | order(label asc),
    tagline,
    currently,
  }
`;
