<script setup>
const props = defineProps({
  categories: { type: Array, default: null },
  tags: { type: Array, default: null },
});

const route = useRoute();

const tags = computed(() => {
  // split tags into three arrays
  const tags = props?.tags || [];
  const tagsLength = tags.length;
  const tagsPerColumn = Math.ceil(tagsLength / 3);
  return [
    tags.slice(0, tagsPerColumn),
    tags.slice(tagsPerColumn, tagsPerColumn * 2),
    tags.slice(tagsPerColumn * 2),
  ];
});
</script>

<template>
  <section class="categories-section">
    <div class="flex-col gap-s">
      <p class="text-mono">Notes from the:</p>
      <ul class="grid-category text-lg">
        <li
          v-for="item in categories"
          :key="item.value.current"
          :class="{ grey: route.query?.category !== item.value.current }"
        >
          <button
            class="category-btn"
            @click="gotoCategory(item.value.current)"
          >
            {{ item.label }}
          </button>
        </li>
      </ul>
    </div>
    <div class="text-mono">
      <div class="tags-section m-b-s header">
        <p class="lined-list-outer">Tagged with:</p>
        <span class="invisible"></span>
        <span class="invisible"></span>
      </div>
      <div class="tags-section">
        <ul
          v-for="(item, index) in tags"
          :key="index"
          class="lined-list lined-list-outer"
          :class="{ 'dk-grey': route.query.tags }"
        >
          <li v-for="tag in item" :key="tag._key">
            <button
              :class="{
                black: route.query?.tags
                  ?.split(',')
                  ?.includes(tag.value.current),
              }"
              @click="gotoTag(tag.value.current)"
            >
              {{ tag.label }}
            </button>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.categories-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--s);
  margin-top: var(--m);
  align-items: start;

  @include media("<ltablet") {
    grid-template-columns: 1fr;
    grid-row-gap: var(--m);
  }
}

.tags-section {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: var(--gutter);
  row-gap: 0;

  ul:not(:last-child) {
    border-bottom: 2px solid var(--black);
    padding-bottom: var(--gap);
    margin-bottom: var(--gap);
  }

  > * {
    @include media(">=ltablet") {
      margin-left: auto;
    }
  }

  @include media("<mobile") {
    grid-template-columns: 1fr 1fr;
  }

  button {
    transition: color 0.2s;
    &:hover {
      color: var(--dk-grey);
    }
  }

  .black {
    color: var(--black);
  }
}

.grid-category {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: var(--gutter);
}

.category-btn {
  line-height: 1;
  transition: color 0.2s;

  &:hover {
    color: var(--black);
  }

  &:focus-visible {
    outline: 1px solid var(--yellow);
  }
}

.text-2xl {
  @include media("<desktop") {
    font-size: var(--font-size-lg);
    line-height: calc(42 / 34);
  }
}

.lined-list,
.lined-list-outer {
  @include media(">=ltablet") {
    // max-width: 250px;
    width: 100%;
  }
}

.header {
  grid-row-gap: 0;
}

.invisible {
  visibility: hidden;
}
</style>
