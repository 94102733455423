import { articleInner, masthead } from "./snippets/blog";

const articlesQuery = groq`*[
  _type == "blogArticle" &&
  select(defined($category) => 
    count((category[].value)[@ in $category]) > 0, 
  true) &&
  select(defined($tags) => 
    count((tags[].value)[@ in $tags]) > 0, 
  true) &&
  select(defined($search) => 
    category[].value match $search || tags[].value match $search || title match $search || pt::text(lede) match $search,
  true)
] | order(publishedAt desc) `;

export const queryPageBlog = groq`*[_type == "blog"]| order(_updatedAt desc)[0]{
  "articles": ${articlesQuery}[$offset...$max]{
    ${articleInner}
  },
  "count": count(${articlesQuery}),
  "categories": *[_type == "blogCategory"] | order(label asc),
  "tags": *[_type == "blogTag"] | order(label asc),
  seo,
}`;

export const queryNotesMasthead = groq`{
  ${masthead},
}`;
